/*
    Enum for Module and ModuleCoreURL mapping in ModuleCore table
*/
export enum ModuleCoreURL {
  HOME = "home",
  ORGANIZATION = "organization",
  SERVICES = "services",
  REPORTS = "reports",
  SETTINGS = "settings",
  HELP = "help",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_OF_SERVICES = "terms-of-services",
  MESSAGE_BOARD = "message-board",
  EVENTS = "events",
  USERS = "users",
  REQUESTS = "requests",
  ORDERS = "orders",
  HISTORY = "history",
  STORE = "store",
  CATEGORY = "category",
  ADMIN = "admin",
  CHAT = "chat",
  CUSTOM_PAGE = "custom-page",
  USER_DEFINED_PAGE = "user-defined-page",
  MODULES = "modules",
  DEPARTMENTS = "departments",
  USER_ROLES = "user-roles",
  ASSET = "asset",
}

/*
    Enum for Module and ModuleCoreCode mapping in ModuleCore table
*/
export enum ModuleCoreCode {
  HOME = "HOME",
  ORGANIZATION = "ORGANIZATION",
  MODULES = "MODULES",
  DEPARTMENTS = "DEPARTMENTS",
  USERS = "USERS",
  USER_ROLES = "USER_ROLES",
  MESSAGE_BOARD = "MESSAGE_BOARD",
  CHAT = "CHAT",
  ADMIN = "ADMIN",
  SETTINGS = "SETTINGS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_SERVICES = "TERMS_OF_SERVICES",
  CUSTOM_PAGE = "CUSTOM_PAGE",
  USER_DEFINED_PAGE = "USER_DEFINED_PAGE",
  EVENTS = "EVENTS",
  SERVICES = "SERVICES",
  REQUESTS = "REQUESTS",
  ORDERS = "ORDERS",
  STORE = "STORE",
  HELP = "HELP",
  HISTORY = "HISTORY",
  REPORTS = "REPORTS",
  CATEGORY = "CATEGORY",
  ASSET = "ASSET",
}

export enum ModuleOperationEnum {
  READ = "Read",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  SHARE = "Share",
}
