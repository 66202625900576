/* eslint-disable @typescript-eslint/naming-convention */
export const APIURLs = {
  //-- Users - STARTS
  SIGNINUSER: "/user/signInUser",
  //-- Users - ENDs

  //-- Notifications - START
  REGISTERDEVICETOKEN: "/notification/registerDeviceToken",
  UPDATEDEVICETOKEN: "/notification/updateDeviceToken",
  GETDEVICETOKENS: "/notification/getDeviceTokens",
  DELETEDEVICETOKEN: "/notification/deleteDeviceToken",
  //-- Notifications - ENDS

  //-- Chats - START
  GETCHATSLIST: "/social/getChatsList",
  GETMESSAGES: "/social/getMessages",
  SAVEGROUP: "/social/saveGroup",
  GETGROUPMESSAGES: "/social/getGroupMessages",
  ADDMEMBERSTOGROUP: "/social/addMembersToGroup",
  // GETFRIENDSLIST: "/social/getFriendsList",
  INVITEMEMBERSTOCHAT: "/social/inviteMembersToChat",
  REMOVEMEMBERSFROMCHAT: "/social/removeMembersFromChat",
  GETGROUPDETAILS: "/social/getGroupDetails",
  ADDADMINTOGROUP: "/social/addAdminToGroup",
  REMOVEGROUPMEMBERS: "/social/removeGroupMembers",
  REMOVEMEMBERFROMADMIN: "/social/removeMemberFromAdmin",
  DELETEGROUP: "/social/deleteGroup",
  RESPONDTOCHATINVITE: "/social/respondToChatInvite",

  // Message-Board Start
  SAVEMBMESSAGE: "/social/saveMBMessage",
  GETMBMESSAGE: "/social/getMBMessages",
  LISTOFCOMMENTS: "/social/getListOfComments",
  SAVEMBMESSAGECOMMENT: "/social/saveMBMessageComment",
  SAVEMBMESSAGELIKE: "/social/saveMBMessageLike",
  LISTOFLIKES: "/social/getListOfLikes",
  DELETMBMESSAGE: "/social/deleteMBMessage",
  REPORTINAPPROPRIATE: "/social/reportMBMessageInappropriate",
  ALLCOMMENTREPLIES: "/social/getAllCommentReplies",
  GETMBMESSAGEDETAILS: "/social/getMBMessageDetails",
  GETALLMBMESSAGEATTACHMENTS: "/social/getAllMBMessageAttachments",

  // Common Start
  GETALLCATEGORY: "/common/getAllCategories",
  SAVECATEGORY: "/common/saveCategory",
  SAVECATEGORYITEM: "/common/saveCategoryItem",
  GETCATEGORYDETAILS: "/common/getCategoryDetails",
  GETCATEGORYITEMDETAILS: "/common/getCategoryItemDetails",
  GETCATEGORYITEMSFORACATEGORY: "/common/getCategoryItemsForACategory",
  GETSTATUSUUIDFORSTATUSCODE: "/common/getStatusUUIDForStatusCode",
  GETMODULESTATUSES: "/common/getModuleStatuses",
  GETMODULECATEGORIES: "/common/getModuleCategories",

  //-- Organization APIs
  GETURANDORGANIZATIONBASEDMODULES:
    "/organization/getURAndOrganizationBasedModules",
  GETORGANIZATIONBASEDMODULES: "/organization/getOrganizationBasedModules",
  GETORGANIZATIONDETAILSBYORGURL:
    "/organization/getOrganizationDetailsByOrgURL",
  GETORGANIZATIONLISTS: "/organization/getOrganizationsList",
  GETORGANIZATIONDETAILS: "/organization/getOrganizationDetails/",
  SAVEORGANIZATION: "/organization/saveOrganization",

  // Modules Start
  GETORGANIZATIONMODULES: "/organization/getOrganizationModules/",
  GETMODULEUSERPERMISSION:
    "/organization/getModuleDetailsWithUserRolePermissions/",
  SAVEORGANIZATIONMODULES: "/organization/saveOrganizationModule",

  // Profile Start
  GETUSERPROFILE: "/user/getUserProfile",
  GETALLCOUNTRIES: "/address/getAllCountries",
  UPDATEUSERPROFILE: "/user/updateUserProfile",
  SAVEPROFILEPICTURE: "/user/saveProfilePictureURL",

  // User Start
  GETORGANIZATIONUSER: "/organization/getOrganizationUsers",
  GETALLUSERROLE: "/organization/getAllUserRoles/",
  SAVEUSERROLEHIE: "/organization/saveUserRoleHierarchy",
  GETALLCOREUSERROLETYPE: "/organization/getAllCoreUserRoleTypes",
  SAVEUSERROLE: "/organization/saveUserRole",

  // Invites Start
  GETINVITATIONLIST: "/alert/getInvitationsList",
  GETCHATINVITEDETAIL: "/alert/getChatInviteDetails",

  // Department Start
  GETALLDEPARTMENT: "/organization/getDepartmentsList",
  GETALLDEPARTMENTDETAILS: "/organization/getDepartmentsList",
  GETDEPARTMENTTYPES: "/organization/getDepartmentTypes",
  SAVEDEPARTMENT: "/organization/saveDepartment",
  SAVEDEPARTMENTTYPE: "/organization/saveDepartmentType",
  GETDEPARTMENTTYPEDETAILS: "/organization/getDepartmentTypeDetails",
  GETDEPARTMENTDETAILS: "/organization/getDepartmentDetails",

  //Asset Start
  GETASSETLIST: "/asset/getAssetList",
  GETASSETTYPES: "/asset/getAssetTypes",
  CREATEASSET: "/asset/createAsset",
  CHECKASSETURLAVAILABILITY: "/asset/checkIfAssetURLExists",
  GETASSETDETAILS: "/asset/getAssetDetails",
  GETASSETTYPEDETAILS: "/asset/getAssetTypeDetails",
  GETUSERASSETTABS: "/asset/getUserAssetTabs",
  SAVEASSETTYPE: "/asset/saveAssetType",
  SAVECUSTODIANDETAIL: "/asset/saveCustodianDetail",
  GETASSETFORMFIELDDATATYPES: "/asset/getAssetFormFieldDataTypes",
  GETASSETDOCUMENTS: "/asset/getAssetDocuments",
  SAVEASSETDOCUMENT: "/asset/saveAssetDocument",
  GETASSETDOCUMENTTYPES: "/asset/getAssetDocumentTypes",
  SAVEASSETDOCUMENTTYPE: "/asset/saveAssetDocumentType",
  GETASSETATTACHMENTS: "/asset/getAssetAttachments",
  SAVEASSETATTACHMENT: "/asset/saveAssetAttachment",
};
